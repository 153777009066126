import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image"

import Seo from 'src/components/seo';

import s from './styles.module.css'

const Nodes = ({ tree }) => (
  <ul>
    <li><header>Pour aller plus loin</header></li>
    {tree.map(n => (
      <Node key={n.target} node={n} />
    ))}
  </ul>
);

const Node = ({ node }) => {
  
  return <li>
    <Link to={node.target}>{node.title}</Link>
    {node.children.length > 0 && <Nodes tree={node.children} />}
  </li>
}

const Pilar = ({ pageContext: { uid, tree, title, meta_description, description, content }, data}) => {
  return <div className={s['global']}>
    <Seo title={title} description={meta_description} />
    <div className={s['content']}>
      <Img alt="Illustration" fluid={data.prismicPilar.data.image.localFile.childImageSharp.fluid} />
      <h1>{title}</h1>
      <p style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: description }} />
      <div style={{marginTop: '2em'}} dangerouslySetInnerHTML={{ __html: content.html }} />
      {tree.length > 0 && <div className={s['tree']}>
        <Nodes tree={tree} />
      </div>}
    </div>
  </div>
}

export default Pilar

export const query = graphql`
  query GetPilar($uid: String) {
    prismicPilar(uid: { eq: $uid })  {
      data {
        image { localFile { childImageSharp { fluid { ...GatsbyImageSharpFluid } } } }
      }
    }
  }
`